export const en = {
    navbar: {
        philosophy: "Philosophy",
        incubator: "Incubator",
        works: "Works",
        contacts: "Contacts",
        getInTouch: "Get In Touch",
    },
    index: {
        hero: {
            title: "",
            payoff: "Market3rs is a martech incubator dedicated to creating synergetic projects that align with our core values. We provide these projects with the expertise and resources they need to thrive."
        },
        services: {
            title: "Our Services",
            payoff: "Market3rs is a martech incubator dedicated to creating synergetic projects that align with our core values. We provide these projects with the expertise and resources they need to thrive.",
            readMore: "Read More",
            "1up": {
                title: "Client engagement through gamification",
                payoff: "We use digital engagement strategies based on gaming assets to captivate your clients."
            },
            power2brands: {
                title: "Brand Equity Monetization",
                payoff: "As a specialized brand agency in the Baltic countries and Poland, we unlcok and monetize brand equity."
            },
            mugo: {
                title: "Sustainability initiatives",
                payoff: "We assist our partners in involving consumers in their sustainability missions."
            },
            smartPromotion: {
                title: "Personalized targeting solutions",
                payoff: "We develop innovative solutions to personalize the targeting of your clients."
            }
        },
        philosophy: {
            title: "Our Philosophy",
            payoff: "Market3rs is a martech incubator dedicated to creating synergetic projects that align with our core values. We provide these projects with the expertise and resources they need to thrive.",
            item1: {
                title: "Digital first",
                payoff: "because we like it, but also because that is what we do best and that is what needed: concrete ideas based on technology.",
            },
            item2: {
                title: "We develop projects that support the market",
                payoff: "we do this by creating better relationships between its players: Retailers, Brands and Consumers.",
            },
            item3: {
                title: "Specialized, in order to excel",
                payoff: "we then choose to grow very vertical projects and do so with specialists who are given the opportunity to focus on what makes the difference.",
            }
        },
        works: {
            title: "Works",
            seeAll: "See all",
            item1: {
                title: "From physical to digital",
            },
            item2: {
                title: "Coupon system",
            }
        },
        clients: {
            title: "Our clients"
        },
    },
    newsletter: {
        title: "Never miss the latest news",
        placeholder: "Enter your email",
        messages: {
            missingEmail: "Please enter a valid email",
            captchaErrorMessage: "The captcha was not solved correctly, please retry",
            successMessage: "You've been successfully subscribed to our newsletter",
            errorMessage: "There was an error, please retry",
        }
    },
    contacts: {
        tag: "Contacts",
        title: "Get in touch",
        fillForm: "Fill in the form or write us at:",
        headquarters: "Operational Headquarters:",
        form: {
            name: "NAME",
            email: "EMAIL",
            message: "MESSAGE",
            send: "Send"
        },
        messages: {
            missingName: "Please enter your name",
            missingEmail: "Please enter a valid email",
            missingMessage: "Please enter a message",
            successMessage: "Message sent successfully",
            errorMessage: "There was an error, please retry",
            captchaErrorMessage: "The captcha was not solved correctly, please retry"
        }
    }
}
