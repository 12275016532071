export const it = {
    navbar: {
        philosophy: "Filosofia",
        incubator: "Incubatore",
        works: "Lavori",
        contacts: "Contatti",
        getInTouch: "Contattaci",
    },
    index: {
        hero: {
            title: "",
            payoff: "Market3rs è un incubatore Martech dedicato allo sviluppo di progetti sinergici e in linea con i nostri valori. Alimentiamo ognuno di questi fornendo le competenze e le risorse necessarie per crescere."
        },
        services: {
            title: "I nostri servizi",
            payoff: "Market3rs è un incubatore Martech dedicato allo sviluppo di progetti sinergici e in linea con i nostri valori. Alimentiamo ognuno di questi fornendo le competenze e le risorse necessarie per crescere.",
            readMore: "Leggi ancora",
            "1up": {
                title: "Gamification per l'ingaggio dei consumatori",
                payoff: "Usiamo strategie di ingaggio digitale basate su contenuti del mondo gaming per emozionare i tuoi clienti."
            },
            power2brands: {
                title: "Monetizzazione del Marchio",
                payoff: "Grazie alla nostra presenza e competenza nei Paesi Baltici e in Polonia, sblocchiamo e monetizziamo il valore dei marchi."
            },
            mugo: {
                title: "Progetti per la sostenibilità",
                payoff: "Aiutiamo i nostri Partner a coinvolgere i propri Clienti sugli obbiettivi di sostenibilità."
            },
            smartPromotion: {
                title: "Soluzioni per le offerte personalizzate",
                payoff: "Sviluppiamo soluzioni innovative per rendere personale il dialogo con i consumatori tramite le offerte di prezzo."
            }
        },
        philosophy: {
            title: "La nostra Filosofia",
            payoff: "Market3rs è un incubatore Martech dedicato allo sviluppo di progetti sinergici e in linea con i nostri valori. Alimentiamo ognuno di questi fornendo le competenze e le risorse necessarie per crescere.",
            item1: {
                title: "Prima di tutti digitali",
                payoff: "perché ci piace, ma anche perché è ciò che facciamo meglio e che serve: progetti operativi basati sulla tecnologia.",
            },
            item2: {
                title: "Noi sviluppiamo progetti che supportano il mercato",
                payoff: "lo facciamo creando una relazione migliore tra i suoi operatori: Retailer, Marchi e Consumatori.",
            },
            item3: {
                title: "Specializzati, per essere eccellenti",
                payoff: "scegliamo quindi di sviluppare progetti verticali e lo facciamo collaborando con specialisti a cui forniamo il supporto necessario perché possano concentrarsi su ciò che fa la differenza.",
            }
        },
        works: {
            title: "Lavori",
            seeAll: "Guardali tutti",
            item1: {
                title: "Dal fisico al digitale",
            },
            item2: {
                title: "Coupon system",
            }
        },
        clients: {
            title: "I nostri clienti"
        },
    },
    newsletter: {
        title: "Non perderti le ultime notizie",
        placeholder: "Registra la tua email",
        messages: {
            missingEmail: "Inserisci una email valida",
            captchaErrorMessage: "Il captcha non è stato risolto correttamente, per favore riprova",
            successMessage: "Ti sei iscritto correttamente alla nostra newsletter",
            errorMessage: "Si è verificato un errore, per favore riprova",
        }
    },
    contacts: {
        tag: "Contatti",
        title: "Contattaci",
        fillForm: "Compila il modulo o scrivici all'indirizzo:",
        headquarters: "Sede operativa:",
        form: {
            name: "NOME",
            email: "EMAIL",
            message: "MESSAGGIO",
            send: "Invia"
        },
        messages: {
            missingName: "Inserisci il tuo nome",
            missingEmail: "Inserisci una email valida",
            missingMessage: "Inserisci un messaggio",
            successMessage: "Messaggio inviato correttamente",
            errorMessage: "Si è verificato un errore, per favore riprova",
            captchaErrorMessage: "Il captcha non è stato risolto correttamente, per favore riprova"
        }
    }
}
